/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccessModuleConfig } from './AccessModuleConfig';
import {
    AccessModuleConfigFromJSON,
    AccessModuleConfigFromJSONTyped,
    AccessModuleConfigToJSON,
} from './AccessModuleConfig';

/**
 * 
 * @export
 * @interface ConfigResponse
 */
export interface ConfigResponse {
    /**
     * G-Suite domain containing user accounts.
     * @type {string}
     * @memberof ConfigResponse
     */
    gsuiteDomain: string;
    /**
     * The GCP project environment in which this All of Us app is running.
     * @type {string}
     * @memberof ConfigResponse
     */
    projectId?: string;
    /**
     * The Firecloud URL to use for REST requests.
     * @type {string}
     * @memberof ConfigResponse
     */
    firecloudURL?: string;
    /**
     * Stackdriver API key for error reporting, scoped to a particular
     * domain. If unset, Stackdriver error reporting should be disabled.
     * @type {string}
     * @memberof ConfigResponse
     */
    publicApiKeyForErrorReports?: string;
    /**
     * The base URL to use for client-side Shibboleth redirects.
     * @type {string}
     * @memberof ConfigResponse
     */
    shibbolethUiBaseUrl?: string;
    /**
     * Whether the Compliance Training access module is enabled.
     * @type {boolean}
     * @memberof ConfigResponse
     */
    enableComplianceTraining?: boolean;
    /**
     * The SAML identity provider ID for Absorb.
     * @type {string}
     * @memberof ConfigResponse
     */
    absorbSamlIdentityProviderId?: string;
    /**
     * The SAML service provider ID for Absorb.
     * @type {string}
     * @memberof ConfigResponse
     */
    absorbSamlServiceProviderId?: string;
    /**
     * Whether the eRA Commons access module is enabled.
     * @type {boolean}
     * @memberof ConfigResponse
     */
    enableEraCommons?: boolean;
    /**
     * Whether a user is allowed to self-bypass. Only enabled in test environments.
     * @type {boolean}
     * @memberof ConfigResponse
     */
    unsafeAllowSelfBypass?: boolean;
    /**
     * The default dollar amount of free credits allotted to each user
     * @type {number}
     * @memberof ConfigResponse
     */
    defaultFreeCreditsDollarLimit?: number;
    /**
     * Whether to show the Event Date modifier in cohort builder
     * @type {boolean}
     * @memberof ConfigResponse
     */
    enableEventDateModifier?: boolean;
    /**
     * Whether users are given the option to link their RAS/ID.me account to AoU
     * @type {boolean}
     * @memberof ConfigResponse
     */
    enableRasIdMeLinking?: boolean;
    /**
     * Whether users are given the option to link their RAS/Login.gov account to AoU
     * @type {boolean}
     * @memberof ConfigResponse
     */
    enableRasLoginGovLinking?: boolean;
    /**
     * The RAS base host name.
     * @type {string}
     * @memberof ConfigResponse
     */
    rasHost?: string;
    /**
     * the point when we give users the option to update the affirmations needed to use the system.
     * @type {number}
     * @memberof ConfigResponse
     */
    accessRenewalLookback?: number;
    /**
     * the point when we give users the option to update their compliance items that involve training.
     * @type {number}
     * @memberof ConfigResponse
     */
    complianceTrainingRenewalLookback?: number;
    /**
     * The RAS OAuth client id.
     * @type {string}
     * @memberof ConfigResponse
     */
    rasClientId?: string;
    /**
     * The URL that can sign out RAS login session.
     * @type {string}
     * @memberof ConfigResponse
     */
    rasLogoutUrl?: string;
    /**
     * The free tier billing account id
     * @type {string}
     * @memberof ConfigResponse
     */
    freeTierBillingAccountId?: string;
    /**
     * 
     * @type {Array<AccessModuleConfig>}
     * @memberof ConfigResponse
     */
    accessModules?: Array<AccessModuleConfig>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ConfigResponse
     */
    currentDuccVersions?: Array<number>;
    /**
     * Whether a captcha check is part of the new user registration process
     * @type {boolean}
     * @memberof ConfigResponse
     */
    enableCaptcha?: boolean;
    /**
     * Whether to enable visual data explorer.
     * @type {boolean}
     * @memberof ConfigResponse
     */
    enableDataExplorer?: boolean;
    /**
     * The tanagra base url
     * @type {string}
     * @memberof ConfigResponse
     */
    tanagraBaseUrl?: string;
    /**
     * Whether users are allowed to pause GKE applications.
     * @type {boolean}
     * @memberof ConfigResponse
     */
    enableGKEAppPausing?: boolean;
    /**
     * Whether users are allowed to choose machine type when creating GKE applications.
     * @type {boolean}
     * @memberof ConfigResponse
     */
    enableGKEAppMachineTypeChoice?: boolean;
    /**
     * If true, show banner notifying users of login issue
     * @type {boolean}
     * @memberof ConfigResponse
     */
    enableLoginIssueBanner?: boolean;
    /**
     * If true, allow for user's initial credits to expire.
     * @type {boolean}
     * @memberof ConfigResponse
     */
    enableInitialCreditsExpiration?: boolean;
    /**
     * Whether users are prevented from accessing the Compliance Training
     * @type {boolean}
     * @memberof ConfigResponse
     */
    blockComplianceTraining?: boolean;
    /**
     * If true, the api is down for maintenance and will block all requests.
     * @type {boolean}
     * @memberof ConfigResponse
     */
    isDownForMaintenance?: boolean;
}

/**
 * Check if a given object implements the ConfigResponse interface.
 */
export function instanceOfConfigResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "gsuiteDomain" in value;

    return isInstance;
}

export function ConfigResponseFromJSON(json: any): ConfigResponse {
    return ConfigResponseFromJSONTyped(json, false);
}

export function ConfigResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gsuiteDomain': json['gsuiteDomain'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'firecloudURL': !exists(json, 'firecloudURL') ? undefined : json['firecloudURL'],
        'publicApiKeyForErrorReports': !exists(json, 'publicApiKeyForErrorReports') ? undefined : json['publicApiKeyForErrorReports'],
        'shibbolethUiBaseUrl': !exists(json, 'shibbolethUiBaseUrl') ? undefined : json['shibbolethUiBaseUrl'],
        'enableComplianceTraining': !exists(json, 'enableComplianceTraining') ? undefined : json['enableComplianceTraining'],
        'absorbSamlIdentityProviderId': !exists(json, 'absorbSamlIdentityProviderId') ? undefined : json['absorbSamlIdentityProviderId'],
        'absorbSamlServiceProviderId': !exists(json, 'absorbSamlServiceProviderId') ? undefined : json['absorbSamlServiceProviderId'],
        'enableEraCommons': !exists(json, 'enableEraCommons') ? undefined : json['enableEraCommons'],
        'unsafeAllowSelfBypass': !exists(json, 'unsafeAllowSelfBypass') ? undefined : json['unsafeAllowSelfBypass'],
        'defaultFreeCreditsDollarLimit': !exists(json, 'defaultFreeCreditsDollarLimit') ? undefined : json['defaultFreeCreditsDollarLimit'],
        'enableEventDateModifier': !exists(json, 'enableEventDateModifier') ? undefined : json['enableEventDateModifier'],
        'enableRasIdMeLinking': !exists(json, 'enableRasIdMeLinking') ? undefined : json['enableRasIdMeLinking'],
        'enableRasLoginGovLinking': !exists(json, 'enableRasLoginGovLinking') ? undefined : json['enableRasLoginGovLinking'],
        'rasHost': !exists(json, 'rasHost') ? undefined : json['rasHost'],
        'accessRenewalLookback': !exists(json, 'accessRenewalLookback') ? undefined : json['accessRenewalLookback'],
        'complianceTrainingRenewalLookback': !exists(json, 'complianceTrainingRenewalLookback') ? undefined : json['complianceTrainingRenewalLookback'],
        'rasClientId': !exists(json, 'rasClientId') ? undefined : json['rasClientId'],
        'rasLogoutUrl': !exists(json, 'rasLogoutUrl') ? undefined : json['rasLogoutUrl'],
        'freeTierBillingAccountId': !exists(json, 'freeTierBillingAccountId') ? undefined : json['freeTierBillingAccountId'],
        'accessModules': !exists(json, 'accessModules') ? undefined : ((json['accessModules'] as Array<any>).map(AccessModuleConfigFromJSON)),
        'currentDuccVersions': !exists(json, 'currentDuccVersions') ? undefined : json['currentDuccVersions'],
        'enableCaptcha': !exists(json, 'enableCaptcha') ? undefined : json['enableCaptcha'],
        'enableDataExplorer': !exists(json, 'enableDataExplorer') ? undefined : json['enableDataExplorer'],
        'tanagraBaseUrl': !exists(json, 'tanagraBaseUrl') ? undefined : json['tanagraBaseUrl'],
        'enableGKEAppPausing': !exists(json, 'enableGKEAppPausing') ? undefined : json['enableGKEAppPausing'],
        'enableGKEAppMachineTypeChoice': !exists(json, 'enableGKEAppMachineTypeChoice') ? undefined : json['enableGKEAppMachineTypeChoice'],
        'enableLoginIssueBanner': !exists(json, 'enableLoginIssueBanner') ? undefined : json['enableLoginIssueBanner'],
        'enableInitialCreditsExpiration': !exists(json, 'enableInitialCreditsExpiration') ? undefined : json['enableInitialCreditsExpiration'],
        'blockComplianceTraining': !exists(json, 'blockComplianceTraining') ? undefined : json['blockComplianceTraining'],
        'isDownForMaintenance': !exists(json, 'isDownForMaintenance') ? undefined : json['isDownForMaintenance'],
    };
}

export function ConfigResponseToJSON(value?: ConfigResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gsuiteDomain': value.gsuiteDomain,
        'projectId': value.projectId,
        'firecloudURL': value.firecloudURL,
        'publicApiKeyForErrorReports': value.publicApiKeyForErrorReports,
        'shibbolethUiBaseUrl': value.shibbolethUiBaseUrl,
        'enableComplianceTraining': value.enableComplianceTraining,
        'absorbSamlIdentityProviderId': value.absorbSamlIdentityProviderId,
        'absorbSamlServiceProviderId': value.absorbSamlServiceProviderId,
        'enableEraCommons': value.enableEraCommons,
        'unsafeAllowSelfBypass': value.unsafeAllowSelfBypass,
        'defaultFreeCreditsDollarLimit': value.defaultFreeCreditsDollarLimit,
        'enableEventDateModifier': value.enableEventDateModifier,
        'enableRasIdMeLinking': value.enableRasIdMeLinking,
        'enableRasLoginGovLinking': value.enableRasLoginGovLinking,
        'rasHost': value.rasHost,
        'accessRenewalLookback': value.accessRenewalLookback,
        'complianceTrainingRenewalLookback': value.complianceTrainingRenewalLookback,
        'rasClientId': value.rasClientId,
        'rasLogoutUrl': value.rasLogoutUrl,
        'freeTierBillingAccountId': value.freeTierBillingAccountId,
        'accessModules': value.accessModules === undefined ? undefined : ((value.accessModules as Array<any>).map(AccessModuleConfigToJSON)),
        'currentDuccVersions': value.currentDuccVersions,
        'enableCaptcha': value.enableCaptcha,
        'enableDataExplorer': value.enableDataExplorer,
        'tanagraBaseUrl': value.tanagraBaseUrl,
        'enableGKEAppPausing': value.enableGKEAppPausing,
        'enableGKEAppMachineTypeChoice': value.enableGKEAppMachineTypeChoice,
        'enableLoginIssueBanner': value.enableLoginIssueBanner,
        'enableInitialCreditsExpiration': value.enableInitialCreditsExpiration,
        'blockComplianceTraining': value.blockComplianceTraining,
        'isDownForMaintenance': value.isDownForMaintenance,
    };
}

